import React from "react";
import Layout from "../../components/layout";
import Buisness from "../../components/Product";

interface BusinessPageProps {
  pageContext: {
    language: string;
  };
}

function BusinessPage({ pageContext }: BusinessPageProps) {
  const { language } = pageContext;

  return (
    <Layout language={language}>
      <Buisness />
    </Layout>
  );
}

export default BusinessPage;
