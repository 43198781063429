import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import Hero from "./Hero";
import SEO from "../seo";
import ProductYoutube from "./ProductYoutube";
import ProductAzarDifference from "./ProductAzarDifference";
import Match from "./Match";
import { v4 as uuidv4 } from "uuid";
import { getIsEnUSBrowser } from "../../utils";
import { useIntl } from "gatsby-plugin-intl";
import Live from "./Live";
import Lounge from "./Lounge";
// import {
//   useEnableVisitorConfig,
//   useGetVisitorConfigs,
// } from "../../hooks/useVisitor";

interface indexProps {}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      width: "100%",
      color: "white",
    },
    container: {
      display: "flex",
      height: "100vh",
    },
  });
});

const Buisness: React.FC<indexProps> = () => {
  const classes = useStyles();
  const isEnUSBrowser = getIsEnUSBrowser();
  const intl = useIntl();
  const [isClient, setisClient] = React.useState(false);

  React.useEffect(() => {
    setisClient(true);
  }, []);

  if (!isClient) {
    return <div />;
  }
  // TODO: 미국의 geolocation을 캐치하는 로직은 보다 고도화되어야 함
  // TODO: (ex. 아래는 Azar live의 코드를 가져온 부분이지만 azar api를 사용하는 것에서 따로 확인을 받아야 사용할 수 있을 듯 함)
  // useGetVisitorConfigs();
  // const isLiveOff = useEnableVisitorConfig("LiveTabDisabled");
  // const isLoungeOff = useEnableVisitorConfig("LoungeDisabled");

  return (
    <div className={classes.root}>
      <SEO
        title="Product"
        description={intl.formatMessage({ id: "business_description" })}
      />
      <div className={classes.container}>
        <Hero key={uuidv4()} />
      </div>
      <ProductYoutube />
      <ProductAzarDifference />
      <Match />
      {!isEnUSBrowser && <Live />}
      {!isEnUSBrowser && <Lounge />}
    </div>
  );
};

export default Buisness;
